import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import theme from "../theme";
import FilterDropdownCheckbox from "./filterDropdownCheckbox";
import FilterDropdownLink from "./filterDropdownLink";
import FilterRange from "./filterRange";
import {useTranslation} from "../../tools/i18n";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useRouter} from "next/router";
import {Button, Drawer} from "@material-ui/core";
import DropdownIndicator from "../../public/images/icons/drop-down.svg";
import Plus from "../../public/images/icons/plus.svg";
import Filter from "../../public/images/icons/filter.svg";
import InStock from "../../public/images/icons/in-stock-switch.svg";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		justifyContent: 'center',
		maxWidth: 300,
		width: `calc(((100% - ${theme.maxWidth}) / 2) - 24px)`,
		[theme.breakpoints.down('lg')]: {
			marginBottom: 0
		},
		'&.open': {
			width: '25%',
			marginBottom: 20,
			padding: '0 0 20px',
			[theme.breakpoints.down('md')]: {
				width: '50%',
				marginBottom: 0,
				padding: 0
			},
			[theme.breakpoints.up('xl')]: {
				width: '25%'
			}
		},
		'@media screen and (max-width: 1296px) and (min-width: 1200px)': {
			'&:not(.open)': {
				top: 0,
				left: 0,
			}
		}
	},
	inner: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		width: '100%',
		maxWidth: theme.maxWidth,
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		},
	},
	filterHeader: {
		display: 'flex',
		flexDirection: 'column',
		padding: 15,
		'& h3': {
			margin: 0,
			...theme.typography.h3,
			textTransform: 'uppercase'
		},
		'& .close': {
			position: 'absolute',
			top: 19,
			right: 12,
			strokeWidth: '0.1px !important'
		}
	},
	clearFilter: {
		marginTop: 20,
		marginLeft: 'auto',
		padding: '5px 12px',
		border: '1px solid #999',
		borderRadius: 0,
		lineHeight: 1.43,
		textTransform: 'none',
		backgroundColor: 'white',
	},
	filters: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'space-between',
		flexGrow: 1,
		order: 10,
		width: '100%',
		margin: 0,
		[theme.breakpoints.down('md')]: {
			justifyContent: 'flex-start',
			overflowY: 'auto',
			overflowX: 'hidden',
			borderTop: '1px solid #e0e0e0'
		},
	},
	triggerWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '25%',
		maxWidth: 300,
		[theme.breakpoints.down('lg')]: {
			position: 'absolute',
			top: 20,
			left: 20,
			maxWidth: 'calc(${theme.maxWidth} / 4)'
		},
		'&.desktop': {
			display: 'none',
			position: 'absolute',
			top: 20,
			left: 'calc((50% - 24px) - ((min(1600px, 75%) / 2) + min(300px, 25%)))',
			['@media only screen and (max-width: 2296px)']: {
				left: 24,
			},
			'&:not(.open)': {
				left: `calc(max(50% - 800px , 24px))`
			},
			[theme.breakpoints.up('lg')]: {
				display: 'block'
			}
		},
		'&.mobile': {
			[theme.breakpoints.up('lg')]: {
				display: 'none'
			}
		}
	},
	filterTrigger: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: '38px',
		width: 'calc(50% - 25px)',
		padding: '2px 10px',
		border: 'none',
		textAlign: 'left',
		whiteSpace: 'nowrap',
		...theme.typography.dropdown,
		backgroundColor: 'white',
		outline: 'none',
		cursor: 'pointer',
		boxShadow: theme.boxShadow.dropdown,
		'& svg': {
			display: 'inline-block',
			fill: 'currentColor',
			lineHeight: 1,
			stroke: 'currentColor',
			strokeWidth: 0,
			color: '#000'
		},
		'.desktop &': {
			width: 'auto',
			padding: 0,
			boxShadow: 'none',
			'& svg': {
				marginRight: 10
			},
			'&.open': {
				'& svg': {
					transform: 'none'
				}
			},
		},
		'&.mobile': {
			minHeight: 0,
			padding: '6px 8px',
			border: '1px solid #666',
			lineHeight: 1.43
		},
		'&.open': {
			'& svg': {
				transform: 'rotateZ(-90deg)'
			}
		},
	},
	inStockIcon: {
		color: 'white',
		'&.open': {
			transform: 'rotate(180deg)'
		}
	},
	filterFooter: {
		position: 'relative',
		order: 20,
		padding: 15
	},
	filterButton: {
		padding: 15,
		width: '100%',
		backgroundColor: '#000',
		borderRadius: 0,
		color: '#fff',
		textTransform: 'none'
	},
	filterDrawer: {
		display: 'flex',
		minWidth: 300
	}
}, {name: 'productFilter'});

function ProductFilters({availableFilters, filters, currentFilters, categories, onFilterChange, block, isOpen = false}) {
	const router = useRouter();
	const isIPad = useMediaQuery(theme.breakpoints.down('md'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const { t } = useTranslation(['general', 'attributes']);
	const classes = useStyles();
	const { query } = router;
	const { order } = query;

	const [state, setState] = useState({
		filterOpen: isOpen
	});

	useEffect(() => {
			setState({ ...state, filterOpen: isOpen })
	}, [isOpen]);

	useEffect(() => {
		if (!isOpen) {
			setState({ ...state, filterOpen: isDesktop })
		}
	}, [isDesktop]);

	function toggleFilterOpen() {
		setState({
			...state,
			filterOpen: !state.filterOpen
		});
	}

	function renderFilter(availableFilters) {
		const filterKeys = Object.keys(availableFilters);
		const filters = [];

		filterKeys.forEach((filterKey) => {
			let filter = availableFilters[filterKey];

			if(filter.type === 'dropdown') {
				filters.push(renderDropdownFilter(filter, filterKey));
			} else if(filter.type === 'range') {
				filters.push(renderRangeFilter(filter, filterKey))
			}
		});

		return filters;
	}

	function isPropertyKey(key) {
		return availableFilters?.properties?.entities?.find(property => property.translated.customFields?.attributeCode === key);
	}

	function renderDropdownFilter(filter, name) {
		return <FilterDropdownCheckbox
			label={filter.label}
			name={name}
			filterKey={filter.key}
			currentFilter={isPropertyKey(name) ? currentFilters['properties'] : currentFilters[name]}
			options={filter.options}
			onChange={onFilterChange}
			className={classes.filterDropdown}
			key={filter.key}/>;
	}

	function renderRangeFilter(filter, name) {
		return <FilterRange name={name} currentFilter={currentFilters[name]} min={parseFloat(filter.min)} max={parseFloat(filter.max)} onChange={onFilterChange} key={name}/>
	}

	function getFilterableCategories() {
		/* Blacklist Slim Aarons, Brands & Gifts */
		const blacklist = ['018f7aef42e5775e97ee4505f381ea4b', '2696df9a97914c58bb869486bd1cb428', '018e61710f757560be598b78375fc686'];

		if(!categories || categories.length <= 0) {
			return []
		}

		return categories.filter((category) => blacklist.indexOf(category.id) < 0).sort((a, b) => {
			if(a.order > b.order) {
				return 1;
			} else if (a.order < b.order) {
				return -1;
			}

			return 0;
		});
	}

	function getCategoryOptions() {
		let filterableCategories = getFilterableCategories();
		let orderString = order ? '?order=' + order : '';

		return filterableCategories.map((category) => {
			return {
				label: category.name,
				value: category.url + orderString,
				children: category.children.map((childCategory) => {
					return {
						label: childCategory.name,
						value: childCategory.url + orderString
					}
				})
			}
		});
	}

	function getCurrentCategory() {
		return router.asPath.split('?')[0];
	}

	function renderFilters() {
		return (
			<div className={classes.filters}>
				{getFilterableCategories() && getFilterableCategories().length > 0 ? <FilterDropdownLink
					options={getCategoryOptions()}
					currentFilter={getCurrentCategory()}
					className={classes.filterDropdown}
				/> : ''}

				{renderFilter(filters).map((filter, key) => (filter))}
			</div>
		)
	}

	function renderFilterHeader() {
		let queryKeys = Object.keys(router.query);
		let hasActiveFilter = false;
		Object.keys(filters).forEach((filterKey) => {
			if(queryKeys.indexOf(filterKey) >= 0) {
				hasActiveFilter = true;
			}
		});

		let routerQuery = {...router.query};
		delete routerQuery.slug;

		Object.keys(filters).forEach((filterKey) => {
			delete routerQuery[filterKey];
		});

		let routerData = {
			pathname: router.asPath.split('?')[0],
			query: {
				...routerQuery
			}
		}

		return (
			<div className={classes.filterHeader}>
				<h3>{t('general:filter-title')}</h3>
				<Plus width={20} className={'close'} onClick={() => setState({filterOpen: false})} style={{transform: 'rotate(45deg)'}}/>
				{hasActiveFilter ? <Button className={classes.clearFilter} onClick={() => router.push(routerData, undefined, {scroll: false})}>{t('general:clear-filter')}</Button> : ''}
			</div>
		);
	}

	function renderFilterFooter() {
		return (
			<div className={classes.filterFooter}>
				<Button className={classes.filterButton} onClick={() => setState({filterOpen: false})}>{t('general:show-products')}</Button>
			</div>
		);
	}

	function filterClose(event) {
		setState({filterOpen: false});
	}

	return (
		<div className={[classes.wrapper, state.filterOpen ? 'open' : ''].join(' ')}>
			<div className={classes.inner}>
				<div className={[classes.triggerWrapper, 'desktop', state.filterOpen ? 'open' : ''].join(' ')}>
					<button className={classes.filterTrigger} onClick={toggleFilterOpen}>
						<Filter width={20}/>
						{state.filterOpen ? t('close-filter') : t('open-filter') }
					</button>

					{false && <button className={classes.filterTrigger}>
						<InStock className={[classes.inStockIcon, state.filterOpen ? 'open' : ''].join(' ')} width={20}/>
						{t('in-stock')}
					</button>}
				</div>

				<button className={[classes.triggerWrapper, classes.filterTrigger, 'mobile', state.filterOpen ? 'open' : ''].join(' ')} onClick={toggleFilterOpen}>
					{t('filter')}
					<DropdownIndicator width={12}/>
				</button>


				{state.filterOpen ?
					isIPad ? <Drawer open={state.filterOpen} onClose={(e) => filterClose(e)} PaperProps={{className: classes.filterDrawer}}>
						{renderFilterHeader()}
						{renderFilters()}
						{renderFilterFooter()}
				</Drawer> : renderFilters()
				: ''}
			</div>
		</div>
	);
}

export default ProductFilters;