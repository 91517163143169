import React, { useEffect, useMemo, useState } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ProductPagination from '../../navigation/pagination';
import ProductFilters from '../../filters/left-filters';
import theme from '../../theme';
import RelevateGrid from "../../products/relevateGrid";
import ProductSorting from "../../cms/blocks/product-listing/sorting";
import { getNewFilterParams } from '../../../functions/plpOps';

const useStyles = makeStyles(
	() => ({
		wrapper: {
			paddingTop: 40,
			paddingBottom: 40
		},
		title: {
			textAlign: 'center',
			'& span': {
				color: '#999'
			}
		},
		subtitle: {
			marginBottom: 40,
			fontSize: 14,
			fontWeight: 300,
			textAlign: 'center'
		},
		searchPhraseAutocomplete: {
			margin: '10px 0 0',
			textAlign: 'center',
			fontStyle: 'italic',
			fontSize: 14,
			fontWeight: 300,
			color: '#333',
			'& span': {
				fontWeight: 'bold'
			}
		},
		container: {
			position: 'relative'
		},
		sortingWrapper: {

		},
		filterGridWrapper: {
			display: 'flex',
			justifyContent: 'center',
			gap: 0,
			paddingTop: 20,
			['@media only screen and (max-width: 1524px)']: {
				justifyContent: 'flex-end',
			},
			[theme.breakpoints.down('lg')]: {
				paddingLeft: 0,
				paddingRight: 0,

			},
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				paddingLeft: 0,
				paddingRight: 0
			},
			[theme.breakpoints.up('lg')]: {
				padding: '0 0',
				'& > :first-child.open': {
					marginRight: '24px',
				},
				'& :first-child > .desktop:first-child': {
					left: 0,
				},
				'@media screen and (min-width: 1296px)': {
					'&:after': {
						content: '""',
						width: 'calc(((100% - 1600px) / 2) - 24px)',
						maxWidth: 300,
						flexShrink: 0
					}
				}
			},

		},
		gridWrapper: {
			width: '75%',
			flexGrow: 1,
			margin: 0,
			[theme.breakpoints.down('md')]: {
				width: '100%'
			},
		}
	}),
	{ name: 'SearchResults' }
);

export default function ElevateListing({ params, setParams, facets, productGroups, sort, totalHits }) {
	const classes = useStyles();
	const [sortings, setSortings] = useState({ current: 'RELEVANCE', options: [] });
	const [filters, setFilters] = useState({});
	const [selectedFilters, setSelectedFilters] = useState({});
	const { page } = params || 1;

	// State
	const products = useMemo(() => {
		let products = [];
		productGroups?.forEach((productSuggestions) => {
			products.push(productSuggestions.products[0]);
		});

		return products;
	}, [productGroups]);

	useEffect(async () => {
		//Set sorting options
		let sortings = [];
		sort.options.forEach((sorting) => {
			sortings.push({
				value: sorting.id,
				label: sorting.label
			});
		});

		setSortings({current: sort.selected, options: sortings});

		// Set filter options
		let newFacets = {};
		let newSelectedFacets = {};
		facets.forEach((facet) => {
			if (facet.type === 'RANGE') {
				newFacets[facet.id] = {
					label: facet.label,
					type:  'range',
					min: facet.min,
					max: facet.max
				}

				if (facet.minSelected) {
					newSelectedFacets[facet.id] = {
						min: facet.minSelected,
						max: facet.maxSelected
					}
				}
			} else {
				let hasSelected = false;

				newFacets[facet.id] = {
					label: facet.label,
					key: facet.id,
					type: facet.type === 'RANGE' ? 'range' : 'dropdown',
					options: facet.values.map((option) => {
						if(option.selected) {
							hasSelected = true;
						}

						return {
							label: option.label,
							value: option.id
						}
					})
				}

				if (hasSelected) {
					newSelectedFacets[facet.id] = facet.values.filter((option) => option.selected).map((option) => option.id);
				}
			}
		});

		setFilters({ ...newFacets });
		setSelectedFilters({ ...newSelectedFacets });
	}, [productGroups, sort, facets]);

	function onPaginate(event, value) {
		setParams({ ...params, initial: false, page: value });
	}

	function onSortChange(data) {
		setParams({ ...params, initial: false, order: data.value });
	}

	function onFilterChange(attribute, data) {
		let newState = {
			...params,
			initial: false,
			page: 1
		};


		if (Array.isArray(data) && data.length > 0) {
			newState[attribute] = data.join(',');
		} else if(Object.keys(data).length > 0) {
			if(facets[attribute]?.min !== data?.min || facets[attribute]?.max !== data?.max) {
				newState[attribute] = 'min:' + data?.min + ',max:' + data?.max;
			} else {
				delete newState[attribute];
			}
		} else {
			delete newState[attribute];
		}

		setParams(newState);
	}

	return (
		<>
            <div className={classes.container}>
                <div className={classes.sortingWrapper}>
                    <ProductSorting
                        sortings={sortings.options}
                        currentSorting={sortings.current}
                        onSortChange={onSortChange}
                    />
                </div>
                <div className={classes.filterGridWrapper}>
                    <ProductFilters
                        currentFilters={selectedFilters}
                        filters={filters}
                        onFilterChange={onFilterChange}
                        isOpen={false}
                    />

                    <Container className={classes.gridWrapper} disableGutters>
                        <RelevateGrid
                            products={products}
                            paramsHash={
                                typeof btoa !== 'undefined'
                                    ? btoa(encodeURIComponent(JSON.stringify(params)))
                                    : Buffer.from(JSON.stringify(params)).toString('base64')
                            }
                        />

                        {totalHits > 48 ? <ProductPagination
                            productPerPage={48}
                            currentPage={page ? parseInt(page) : 1}
                            maxPage={Math.ceil(totalHits / 48) || 0}
                            onChange={onPaginate}
                        /> : ''}
                    </Container>
                </div>
            </div>
		</>
	);
}
